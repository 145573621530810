$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.LoadingPanel {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100svw;
  height: 100vh;
  z-index: 2;
  background-color: #fff;
}
