.DailyBonusPod {
  color: $primaryColorInverted;
  background-color: $primaryColor;

  .Pod__Title {
    color: $primaryColorInverted;
  }
}

.DailyBonusPod__Thumbnail {
  box-shadow: 0 0 0 3px $secondaryColor;
}