@use 'sass:map';

.GridUpsellPod {
  background-color: $theme;

  > section:first-child {
    p, h3 {
      color: $primaryColorInverted;
    }
  }
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: $radius;
    box-shadow: 0 0 0 1px $lightBorderColor inset;
    pointer-events: none;
  }
}
