.PictureEditPage {
  small {
    font-size: 80%;
  }

  .ArrowIcon {
    padding: $marginSm1;
    background-color: $primaryColor;
    border-radius: 50%;
    color: $theme;
  }

  .AvatarSelectionForm {
    .UserAvatar {

      > span {
        border: 2px solid $primaryColor !important;
      }
    }
  }
}