.DailySearchPage {
  &__Search {
    input {
      background-color: #FFF;
    }
    input::placeholder {
      color: #A4A4A4 !important;
    }
    .IconFA {
      color: #A4A4A4BB !important;
    }
  }

  &__GameList {
    scrollbar-color: $bgColorSecondary white;
    background-color: $bgColorLight;
  }
}