.DailyTaxonomySidebar {
  color: $fontColor;
  background-color: $theme;
  border-right: solid 1px $buttonBorderColor;

  &__ScrollContainer {
    scrollbar-color: $bgColorSecondary white;
  }
  
  &__Footer {
    background-color: $themeInverted;
    
  }
}
