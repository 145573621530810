$inProgressColor: #195AAB;
$notStartedColor: $inProgressColor;
$completeColor: #0BAC8B;
$futureColor: #777777;

.ArchiveTile {
  background-color: #FFFFFF;

  &:not(.ArchiveTile--Upsell) {
    border-color: $notStartedColor;

    border-width: 1px;
    border-style: solid;
  }

  &--state-notStarted {
    color: $notStartedColor;
    border-color: $notStartedColor;
  }

  &--state-inProgress,
  &--state-started {
    color: $inProgressColor;
    border-color: $inProgressColor;
  }

  &--state-complete {
    color: $completeColor;
    border-color: $completeColor;
  }

  &--state-future {
    color: $futureColor !important;
    border-color: $futureColor !important;
  }

  &--state-today {
    color: #FFFFFF;
    
    &.ArchiveTile--state-notStarted {
      background-color: $notStartedColor;
    }

    &.ArchiveTile--state-inProgress,
    &.ArchiveTile--state-started {
      background-color: $inProgressColor;
    }
  
    &.ArchiveTile--state-complete {
      background-color: $completeColor;
    }
  }

}

a.ArchiveTile:not(.ArchiveTile--Upsell):hover {
  background-color: #F6F6F6;

  &.ArchiveTile--state-today {
    
    &.ArchiveTile--state-notStarted {
      background-color: rgba($notStartedColor, 0.9);
    }

    &.ArchiveTile--state-inProgress,
    &.ArchiveTile--state-started {
      background-color: rgba($inProgressColor, 0.9);
    }
  
    &.ArchiveTile--state-complete {
      background-color: rgba($completeColor, 0.9);
    }
  }
}

.ArchiveTile--Upsell:hover {
  color: $primaryColor;
}

.ArchiveStatusIcon {

  svg {
    color: $fontColor;
  }

  &--state-notStarted {
    svg {
      color: $notStartedColor;
    }
  }

  &--state-inProgress,
  &--state-started {
    svg {
      color: $inProgressColor;
    }
  }

  &--state-complete {
    svg {
      color: $completeColor;
    }
  }

  &--state-future {
    svg {
      color: $futureColor;
    }
  }

  &--state-inverted {
    svg {
      color: #FFFFFF !important;
    }
  }
}
