.Well {
  background-color: $bgColorLight;
  color: $fontColor;

  &--success {
    background-color: $successColor;
  }

  &--error {
    background-color: $errorColor;
  }

  &--primary {
    background-color: $primaryColor;
  }

  &--secondary {
    background-color: $secondaryColor;
  }

  &--grey {
    background-color: #777;
  }

  &--white {
    background-color: #FFF;;
  }

  &--success,
  &--error,
  &--primary,
  &--secondary,
  &--grey {
    color: $primaryColorInverted;

    .Title,
    .Icon {
      color: $primaryColorInverted;
    }
  }
  
}