.RatingsPicker__Tags {
  background-color: $primaryColor;
  color: $primaryColorInverted;
  box-shadow: $shadow;

  &::before {
    color: $primaryColor;
  }
  
  span {
    &:not(.--selected) {
      &:hover {
        background-color: $appHeaderActiveLinkShadowColor;
        border-color: $appHeaderActiveLinkShadowColor;
      }
    }

    &.--selected {
      background-color: $secondaryColor;
      color: $secondaryColorInverted;
      border-color: $secondaryColor;
    }
  }
}
