@use 'sass:map';

.BonusGameTile {
  background: $theme;

  > section:first-child {
    background-color: map.get($gameColors, 'maroon');

    p, h3 {
      color: $primaryColorInverted;
    }
  }
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: $radius;
    box-shadow: 0 0 0 1px $lightBorderColor inset;
    pointer-events: none;
  }

  li {
    &:hover {
      color: $primaryColor;
      
      a img {
        transition: box-shadow 0s ease;
      }
    }
  }
}
