.Table {
  th {
    color: $primaryColorInverted;
    background-color: $primaryColor;
  }

  tbody {
    tr:nth-child(2n) {
      td {
        background-color: $bgColor;
      }
    }
  }
}