.GamePlayerHeader {
  background-color: $theme;

  aside {
    color: $lightColor;
  }

  .Icon {
    color: $primaryColor;
  }
}
