.DailyAppHeader {
  color: $primaryColorInverted;

  &__Inner {
    background-color: $primaryColor;
  }
}

.DailySidebarMenu {
  background-color: $theme;
}
