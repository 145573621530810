.AppHeader {
  > .Flex {
    color: $primaryColorInverted;
    background: $primaryColor;
    background-image: linear-gradient(to bottom, $primaryColor, $tertiaryColor);
    box-shadow: 0 2px 6px $buttonBorderColor;
  }
}

// ---

.AppHeader__DesktopHeaderLink {
  text-shadow: 0 2px 4px $buttonBorderColor;

  &--active {
    text-shadow: 0 0px 6px $appHeaderActiveLinkShadowColor;

    .HeaderLinkCaret {
      filter: grayscale(1) brightness(2);
    }
  }
}

// ---

.AppHeader__MobileOverlay {
  background: rgba($bgColor, 0.85);
}

.AppHeader__Menu {
  color: $fontColor;
  background: $theme;
  box-shadow: 2px 0 6px $buttonBorderColor;

  &::before {
    @include isMobileDevice {
      background: $primaryColor
    }
  }

  @include isDesktopDevice {
    color: $primaryColorInverted;
    background: rgba($appHeaderBg, 0.9);
    box-shadow: 0 2px 6px $buttonBorderColor;
  }
}

// ---

.HeaderLinkCaret {
  fill: $bgColorSecondary;
}

// ---

.Flex.--loggedInUser {
  .SwSearch:last-child {
    display: none; // doing this so we don't see two search boxes on the screen on page load; CSS timing thing
  }
}

.SwSearch {
  margin: 0 !important;

  .Input,
  .Button {
    background-color: $theme;
    border-color: $theme;
    box-shadow: 0 2px 6px 0 $buttonBorderColor;
    transition: box-shadow 120ms ease-in-out !important;

    &:hover,
    &--hovered {
      border-color: $theme;
    }
  
    &:focus,
    &--focused,
    &:active,
    &--active {
      border-color: $theme;
      box-shadow: 0 0 6px 1px $appHeaderActiveLinkShadowColor;
    }
  }

  .Icon {
    cursor: pointer;
    pointer-events: all;
  }
}
