.MemberProfilePage {

  &__Content {
    .UserAvatar {

      box-shadow: none !important;
  
      > span {
        border-radius: $radius;
        border: 2px solid $primaryColor !important;
      }
      
    }
  }

}