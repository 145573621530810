@use 'sass:map';

.PromoTile {
  background: $theme;
  color: $theme;
  transition: box-shadow 0.2s ease;

  &:hover {
    box-shadow: 0 2px 4px rgba(#000, 0.1);
    transition: box-shadow 0s ease;
  }

  > figure {
    background-color: $bgColor;
    display: flex;
    background-image: url('/images/onlineCtaIconBg.png');
    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden;
  }

  section {
    background-color: #195AAB;
  }
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: $radius;
    box-shadow: 0 0 0 1px $buttonBorderColor inset;
    pointer-events: none;
  }
}

