.DownloadGameTile {
  background: $theme;
  transition: box-shadow 0.2s ease;

  > figure {
    background-color: $bgColor;
  }
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: $radius;
    box-shadow: 0 0 0 1px $buttonBorderColor inset;
    pointer-events: none;
  }
}
