.GamesForPcPage__InfoBlock {
  em,
  .IconFA {
    color: $secondaryColor;
  }
}

.GamesForPcPage__TitleBlock {
  .Title > span::after {
    border-bottom-color: $secondaryColor;
  }
}

.GamesForPc__FooterBlock {
  .Flex > div {
    width: 100%;
    text-align: center;

    &:first-child {
      position: relative;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: $spacing * 2;
        bottom: $spacing * 2;
        left: 0;
        width: 100%;
        background-color: $primaryColor;
        border-radius: $radius;
      }

      > * {
        position: relative;
        z-index: 1;
      }
    }
  }
}