.DailyArchive__Row a {
  &:hover {
    background-color: rgba($primaryColor, 0.1);
    
    .DailyArchive__Thumbnail > span:last-child {
      box-shadow: 0 0 0 1px $primaryColor, 0 4px 6px 1px rgba(0, 0, 0, 0.4);
    }
  }


  &.--today {
    &:hover {
      .DailyArchive__Thumbnail > span:last-child {
        box-shadow: 0 0 0 1px $secondaryColor, 0 4px 6px 1px rgba(0, 0, 0, 0.4);
      }
    }
  }
}

.DailyArchive__Row {
  &.--hasBorder:not(:last-child) {
    padding-bottom: $spacing;
    border-bottom: solid 1px $borderColor;
  }

  > a {
    &.--today {
      background-color: $primaryColor;
      color: $primaryColorInverted !important;

      > .DailyArchive__Thumbnail {
        color: $secondaryColor;
      }
      
      &.RouterLink {
        color: $primaryColorInverted !important;
        text-decoration: none !important;
      }
    }
  }
}