body[data-theme=default] {
  @import '@/node_modules/react-commons/src/lib/styles/util/themePalette';
  @import '@/node_modules/react-commons/src/lib/styles/util/themeDefaults';

  // Default theme palette
  $swWhite: #FFFFFF;
  $swGrayLight: #DFDFDF;
  $swGrayLighter: #EDEDED;
  $swBlue: #195AAB;
  $swBlueDark: #21518E;
  $swBlueLight: #3377CC;
  $swBlueLightest: #74ADF3;
  $swBlueAlt: #125CB8;
  $swGold: #FF9900;

  // Theme variables 
  $theme: $swWhite;
  $themeInverted: $swBlue;
  $bgColor: $swGrayLight;
  $bgColorLight: $swGrayLighter;
  $bgColorSecondary: $swBlueLightest;
  $shadow: 0 0.5em 1em -0.125em rgba(#000, 0.1), 0 0px 0 1px rgba(#000, 0.02) !default;
  $appHeaderBg: $swBlueAlt;
  $appHeaderActiveLinkShadowColor: $swBlueLightest;
  $hrColor: $borderColor;

  $fontColor: $swBlue;
  $lightColor: $swBlueLight;
  $strongColor: $fontColor;
  $titleColor: $fontColor;
  $subtitleColor: $swBlueLight;

  $primaryColor: $swBlue;
  $primaryColorInverted: $swWhite;
  $secondaryColor: $swGold;
  $secondaryColorInverted: $swWhite;
  $tertiaryColor: $swBlueDark;
  $tertiaryColorInverted: $swWhite;

  $boxLinkHoverShadow: $shadow;
  $boxLinkActiveShadow: $shadow;
  
  $errorColor: #DC3535;
  $warningColor: #F49D1A;
  $successColor: #03C988;

  $inputBgColor: $bgColor;
  $inputBorderColor: $bgColor;
  $inputHoverBorderColor: $lightColor;
  $inputIconColor: $lightColor;
  $inputIconActiveColor: $primaryColor;

  $buttonBorderColor: $swBlue;
  $lightBorderColor: #A4A4A4;

  $modalCardHeadBgColor: $theme;
  $modalBgColor: rgba($bgColor, 0.9);
  $modalBgFilter: blur(1px);
  
  $routerLinkColor: $secondaryColor;

  .Notification {
    padding-right: 4rem;
    border: solid 3px #fff;
    font-size: 90%;
    font-weight: bold;
    box-shadow: $shadow;
  
    .Delete {
      margin-top: 0.6rem;
      margin-right: 0.5rem;
      border: solid 1px #fff;
      background-color: transparent !important;
    }
  }

  $skeletonTextBg: rgba(#DFE6EC, 0.65); 

  @keyframes pageLoaderGlowDefault {
    0% {
      background-color: $swBlueLightest;
    }
    45% {
      background-color: lighten($swBlueLightest, 4);
    }
    55% {
      background-color: $swBlueLightest;
    }
    100% {
      background-color: darken($swBlueLightest, 4);
    }
  }
  .PageLoader {
    animation-name: pageLoaderGlowDefault;
  }

  color: $fontColor;
  background-color: $swWhite;

  // @include isDesktopDevice {
  //   background-color: $bgColor;
  //   background-image: url('../../public/images/background.jpg');
  //   background-repeat: no-repeat;
  //   background-attachment: fixed;
  //   background-size: cover;
  //   background-position: 50% 0;
  // }

  @import '@/node_modules/react-commons/src/lib/styles/util/themeDerived';
  @import '@/styles/util/theme';
}