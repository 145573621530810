.UnlimitedPage {

  .strikeThrough {
    opacity: 0.6;
  }

  &.UnlimitedPage__classic,
  &.UnlimitedPage__contrast {
    background: $primaryColorInverted;

    > .App__Main {
      .Title, .Block, p {
        color: #1F1F1F;
      }

      .Button--primary {
        color: $primaryColorInverted;
      }
    }
  }

  .Disclaimer {
    color: #6F6F6F;

    p {
      margin: $marginSm1 0;
    }
  }
}
