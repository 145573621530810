body[data-theme=modernDark] {
  @import '@/node_modules/react-commons/src/lib/styles/util/themePalette';
  @import '@/node_modules/react-commons/src/lib/styles/util/themeDefaults';

  $isDark: true;

  $theme: #333;
  $themeInverted: #eee;
  $bgColor: #111;
  $bgColorSecondary: #222;
  $bgColorLight: #333;

  $fontColor: #fff;
  $lightColor: #777;
  $strongColor: $fontColor;
  $titleColor: $fontColor;
  $subtitleColor: $fontColor;

  $hrColor: $lightColor;
  $inputBorderColor: $lightColor;
  $buttonBorderColor: $lightColor;
  $lightBorderColor: #A4A4A4;


  color: $fontColor;
  background-color: $theme;

  @import '@/node_modules/react-commons/src/lib/styles/util/themeDerived';
  @import '@/styles/util/theme';
}
