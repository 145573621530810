.SubscriptionStatusPage {
  &__Premium {

    border: 1px solid black;
    color: $primaryColorInverted;

    .Well li {
      color: $primaryColor;
    }

    &:after {
      background: linear-gradient(0, $primaryColor, rgba($primaryColor, 0) 100%);
    }
  }

  &__Free {
    border: 1px solid black;
    color: $primaryColorInverted;
  
    &:after {
      $gradientColor: #A2A2A2;
      background: linear-gradient(0, $gradientColor, rgba($gradientColor, 0) 150%);
    }
  }
}



.SubscriptionStatusPage__Option {

  &.--premium:not(.--selected) {
    background-color: $secondaryColor;
    color: $secondaryColorInverted;
  }

  &.--selected {
    &::after {
      content: '✓';
      position: absolute;
      top: -0.5rem;
      right: -0.5rem;
      width: 1.5rem;
      height: 1.5rem;
      background: $primaryColor;
      color: $primaryColorInverted;
      border-radius: $radiusSm;
      text-align: center;
      vertical-align: middle;
      line-height: 1.5rem;
    }
  }
}