.SwGamePlayer {
  background-color: $theme;
}

.SwGamePlayer__PlayButton {
  transition: all 240ms ease;
  box-shadow: 0 0 0 3px rgba($primaryColorInverted, 0.8), 0 4px 10px rgb(0, 0, 0, 0.35) !important;
  text-shadow: -1px -1px 0 $lightColor, 1px 1px 0 $lightColor, 3px 3px 0 $lightColor;

  &:hover {
    box-shadow: 0 0 0 4px rgba($primaryColorInverted, 1), 0 4px 10px rgb(0, 0, 0, 0.65) !important;
  }
}

.SwGamePlayer--autoPlay {
  .GamePlayer__PreviewOverlay {
    background-color: $theme;
    background-size: contain;
    background-position: center;

    + .GamePlayer__Overlay {
      backdrop-filter: none !important;
    }
  }
}
