@import '@/node_modules/react-commons/src/lib/styles/theme';

// Custom theme variables

$appHeaderBg: #000 !default;
$appHeaderActiveLinkShadowColor: #fff !default;
$routerLinkColor: $primaryColor !default;
$skeletonTextBg: rgba(#aaa, 0.5) !default;
$gameColors: (
  'yellowGreen': #8DC73F,
  'halloweenOrange': #F36523,
  'persianGreen': #0BAC8B,
  'maroon': #E31B7B,
  'purple': #C32AEA,
  'lightBlue': #379AC4,
  'yellow': #F7C21E,
  'brown': #C97330,
  'red': #DB3E3E,
  'goldenrod': #FEA800,
) !default;

// Custom theme styles

::selection {
  background: rgba($bgColorSecondary, 0.35);
}

.RouterLink {
  color: $routerLinkColor;
  text-decoration: underline;
  transition: color 120ms ease;

  &:hover {
    color: darken($primaryColor, 2.5%)
  }
}

.Button {
  transition: background-color 120ms ease;

  &:not(.Button--secondary).Button--secondaryOutline {
    color: $secondaryColor;

    &:hover {
      border-color: $secondaryColor;
      color: $secondaryColor;
    }
    &:focus {
      border-color: $secondaryColor;
      color: $secondaryColor;
      &:not(:active) {
        box-shadow: 0 0 0 0.125em rgba($secondaryColor, 0.25);
      }
    }
    &:active {
      border-color: $secondaryColor;
      color: darken($secondaryColor, 5%);
    }
  }

  &.Button--primary,
  &.Button--secondary {
    box-shadow: 0 2px 6px rgba(#000, 0.2);
  }
}

.Button,
.RouterLink {
  &:active {
    transform: translateY(1px);
  }
}

.Dropdown__Content {
  border: solid 1px $primaryColor;
  box-shadow: 0 4px 6px rgba(#000, 0.2);

  // Firefox
  scrollbar-width: auto;
  scrollbar-color: $primaryColor $theme;

  /* Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    border-radius: $dropdownContentRadius;
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    border-radius: $dropdownContentRadius;
    background: $theme;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $primaryColor;
    border-radius: $dropdownContentRadius;
    border: 3px solid $theme;
  }
}

.DropdownItem {
  font-size: 80%;
}

.ModalCard {
  border: solid 2px $primaryColor;
  box-shadow: $shadow;
  border-radius: $radius;
}

.Notification {
  padding-right: 4rem;
  border: solid 3px #fff;
  font-size: 90%;
  font-weight: bold;
  box-shadow: $shadow;

  .Delete {
    margin-top: 0.6rem;
    margin-right: 0.5rem;
    border: solid 1px #fff;
    background-color: transparent !important;
  }
}

.GamePlayer__CloseButton {
  font-weight: lighter;
  background: $primaryColor;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 0.99;
  font-size: 2rem;
  backdrop-filter: none;
  color: $primaryColorInverted;
  border: solid 2px $primaryColorInverted;
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 6px;
  border-radius: 100px;
  display: block;
  text-align: center;
}

@keyframes skeletonLoading {
  0% {
    opacity: 0;
    left: 0;
  }
  25% {
    opacity: 0.65;
    left: 50%;
  }
  50% {
    opacity: 0;
    left: 100%;
  }
  100% {
    opacity: 0;
    left: 100%;
  }
}

.--skeletonLoading {
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 30%;
    background: linear-gradient(to right, rgba($theme, 0) 0%, rgba($theme, 1) 45%, rgba($theme, 1) 55%, rgba($theme, 0) 100%);
    animation-name: skeletonLoading;
    animation-iteration-count: infinite;
    animation-duration: 3s;
    animation-timing-function: linear;
    transform: translateX(-50%) skewX(-20deg);
  }
}

// Theme Helpers

@each $colorName, $color in $gameColors {
  .--hasBackgroundColor__#{$colorName} {
    background-color: $color !important;
    color: $primaryColorInverted !important;
  }

  .--hasColor__#{$colorName} {
    color: $color !important;
  }

  .--hasBorderColor__#{$colorName} {
    border-color: $color !important;
  }
}

.--hasBackgroundColor__Primary {
  background-color: $primaryColor !important;
  color: $primaryColorInverted !important;
}

.--hasColor__Primary {
  color: $primaryColor !important;
}

.--hasBorderColor__Primary {
  border-color: $primaryColor !important;
}

.--hasBackgroundColor__Secondary {
  background-color: $secondaryColor !important;
  color: $primaryColorInverted !important;
}

.--hasColor__Secondary {
  color: $secondaryColor !important;
}

.--hasBorderColor__Secondary {
  border-color: $secondaryColor !important;
}

/* 
 * Imports
 */

// Components
@import '@/components/AdjustableAccordion/index.theme.scss';  
@import '@/components/AppFooter/index.theme.scss';  
@import '@/components/ModernAppFooter/index.theme.scss';  
@import '@/components/AppHeader/index.theme.scss';  
@import '@/components/ArticleContent/index.theme.scss';
@import '@/components/DailyArchive/index.theme.scss';  
@import '@/components/DiscontinuedGame/index.theme.scss';  
@import '@/components/FeaturedCarousel/index.theme.scss';  
@import '@/components/Friend/index.theme.scss';  
@import '@/components/GenreBreadcrumbs/index.theme.scss';  
@import '@/components/MobileGameButton/index.theme.scss';  
@import '@/components/MobileUpsellBanner/index.theme.scss';  
@import '@/components/Popover/index.theme.scss';  
@import '@/components/RatingsPicker/index.theme.scss';  
@import '@/components/SwGamePlayer/index.theme.scss';  
@import '@/components/SwGameRow/index.theme.scss';  
@import '@/components/SwGameTile/index.theme.scss';  
@import '@/components/Table/index.theme.scss';  
@import '@/components/UpsellBanner/index.theme.scss';  
@import '@/components/UpsellPod/index.theme.scss';  
@import '@/components/DailyUpsellPod/index.theme.scss';  
@import '@/components/MobileUpsellPod/index.theme.scss';  
@import '@/components/pods/DailyBonusPod/index.theme.scss';
@import '@/components/LoginButton/index.theme.scss';
@import '@/components/AdFallback/index.theme.scss';
@import '@/components/AdFallbackBanner/index.theme.scss';
@import '@/components/forms/EmailEditForm/index.theme.scss';

// Daily Games Components
@import '@/components/ArchivesUpsellTile/index.theme.scss'; 
@import '@/components/DailyAppHeader/index.theme.scss';
@import '@/components/DailyAppFooter/index.theme.scss';
@import '@/components/DailyBlogTile/index.theme.scss';
@import '@/components/DailyGameTile/index.theme.scss';
@import '@/components/BonusGameTile/index.theme.scss';
@import '@/components/DailySidebarMenu/index.theme.scss';
@import '@/components/DownloadGameTile/index.theme.scss';
@import '@/components/GameRequirementsTile/index.theme.scss';
@import '@/components/gamePage/Archive/index.theme.scss';
@import '@/components/gamePage/Discussion/index.theme.scss';
@import '@/components/gamePage/Leaderboards/index.theme.scss';
@import '@/components/GamePlayerHeader/index.theme.scss';
@import '@/components/DailyHero/index.theme.scss';
@import '@/components/SignupPanelCTA/index.theme.scss';
@import '@/components/PurchasePanelCTA/index.theme.scss';
@import '@/components/DailyTaxonomySidebar/index.theme.scss';
@import '@/components/OnlineSignupCTA/index.theme.scss';
@import '@/components/FormPod/index.theme.scss';
@import '@/components/AccountEditNav/index.theme.scss';
@import '@/components/Well/index.theme.scss';
@import '@/components/dailyUpsellPods/GridUpsellPod/index.theme.scss';
@import '@/components/dailyUpsellPods/ImageUpsellPod/index.theme.scss';
@import '@/components/FullRangePaginator/index.theme.scss';
@import '@/components/PromoTile/index.theme.scss';
@import '@/components/FavoritesDrawer/index.theme.scss';
@import '@/components/forms/VerifyEmailForm/index.theme.scss';

// Layouts
// ...

// Pages
@import '@/pages/modern/index.theme.scss';
@import '@/pages/gamelanding/modern/[slug]/index.theme.scss';
@import '@/pages/games-for-pc/index.theme.scss';
@import '@/pages/unlimited/index.theme.scss';
@import '@/pages/account/user/edit/subscription/index.theme.scss';
@import '@/pages/search-modern/index.theme.scss';
@import '@/pages/account/user/edit/picture/index.theme.scss';
@import '@/pages/member/profiles/[username]/index.theme.scss';
@import '@/pages/about/index.theme.scss';
@import '@/pages/download/special/new-releases/modern/index.theme.scss';
