.AboutPage__Content {

  border: 1px solid $borderColor;

  .photoWithCaption {

    img {
      border: 1px solid $primaryColor;
      background-color: white;
    }

    p:last-child {
      color: $primaryColor;
    }

  }

  .YearSideBySide {
    .Line:after {
      border: 1px dashed rgba($primaryColor, 0.5);
    }
  }

  .TitleWithYear {
    p {
      color: $primaryColor;
    }
  }

  a {
    color: $primaryColor;
  }

}