.FavoritesDrawer {
  background-color: $bgColorLight;
  color: $fontColor;

  .FavoritesGrid {
    &__More {
      color: $primaryColorInverted;
      background-color: $primaryColor;
    }

    &__Game,
    &__More {

      &:hover:after {
        background-color: rgba(0,0,0,0.15);
      }

    }

  }

}
