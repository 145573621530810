.PurchasePanelCTA {

  &:before {
    background: linear-gradient(0, $primaryColor, rgba($primaryColor, 0) 720px);
  }

  h2,
  li {
    color: $theme;
  }
}
