body[data-theme=modern] {
  @import '@/node_modules/react-commons/src/lib/styles/util/themePalette';
  @import '@/node_modules/react-commons/src/lib/styles/util/themeDefaults';



  /*
   * Internal variables
   */
  // Note: these variables shouldn't be used outside this file

  // Default theme palette
  $swBlack: #1F1F1F;
  $swWhite: #FFFFFF;
  $swGrayLight: #DFDFDF;
  $swGrayLighter: #EDEDED;
  $swBlue: #195AAB;
  $swBlueDark: #21518E;
  $swBlueLight: #3377CC;
  $swBlueLightest: #74ADF3;
  $swBlueAlt: #125CB8;
  $swGold: #FF9900;
  $swAccent: #67CF67;



  /* 
   * External variables
   */

  // Theme variables 
  $theme: $swWhite;
  $themeInverted: $swBlue;
  $bgColor: $swGrayLight;
  $bgColorLight: $swGrayLighter;
  $bgColorSecondary: $swBlueLightest;
  $shadow: 0 0.5em 1em -0.125em rgba(#000, 0.1), 0 0px 0 1px rgba(#000, 0.02) !default;
  $appHeaderBg: $swBlueAlt;
  $appHeaderActiveLinkShadowColor: $swBlueLightest;
  $hrColor: $borderColor;

  $fontColor: $swBlack;
  $lightColor: $swBlueLight;
  $strongColor: $fontColor;
  $titleColor: $fontColor;
  $subtitleColor: $swBlueLight;

  $primaryColor: $swBlue;
  $primaryColorInverted: $swWhite;
  $secondaryColor: $swGold;
  $secondaryColorInverted: $swWhite;
  $tertiaryColor: $swBlueDark;
  $tertiaryColorInverted: $swWhite;

  $boxLinkHoverShadow: $shadow;
  $boxLinkActiveShadow: $shadow;
  
  $errorColor: #E64531;
  $warningColor: #F7C21E;
  $successColor: #67CF67;

  $buttonBorderColor: rgba(#000, 0.36);
  $lightBorderColor: #A4A4A4;

  $inputBgColor: $swGrayLighter;
  $inputBorderColor: $swGrayLight;
  $inputHoverBorderColor: $lightColor;
  $inputIconColor: $lightColor;
  $inputIconActiveColor: $primaryColor;
  $inputDisabledColor: rgba($fontColor, 0.6);

  $modalCardHeadBgColor: $theme;
  $modalBgColor: rgba(#000, 0.35);
  $modalBgFilter: blur(1px);

  $skeletonTextBg: rgba(#DFE6EC, 0.65); 

  @keyframes pageLoaderGlowDefault {
    0% {
      background-color: $swBlueLightest;
    }
    45% {
      background-color: lighten($swBlueLightest, 4);
    }
    55% {
      background-color: $swBlueLightest;
    }
    100% {
      background-color: darken($swBlueLightest, 4);
    }
  }
  .PageLoader {
    animation-name: pageLoaderGlowDefault;
  }

  color: $fontColor;
  background-color: $swWhite;

  // @include isDesktopDevice {
  //   background-color: $bgColor;
  //   background-image: url('../../public/images/background.jpg');
  //   background-repeat: no-repeat;
  //   background-attachment: fixed;
  //   background-size: cover;
  //   background-position: 50% 0;
  // }

  @import '@/node_modules/react-commons/src/lib/styles/util/themeDerived';
  @import '@/styles/util/theme';
}