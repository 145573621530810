.FullRangePaginator {
  &__Button {

    &--Previous,
    &--Next {
      .IconFA {
        font-weight: lighter;
      }
    }

    &--selected {

      &:hover,
      &:active,
      &:focus {
        color: $primaryColorInverted;
        border-color: $secondaryColor;
        box-shadow: none !important;
      }

      background-color: $secondaryColor;
      color: $primaryColorInverted;
      border-color: $secondaryColor;

      > span {
        font-weight: bolder !important;
      }
    }

  }
}
