.FormPod {
  .Title,
  .FieldLabel
  {
    font-weight: lighter;
  }

  .Icon {
    color: $lightBorderColor;
  }
}
