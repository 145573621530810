.DownloadNewReleasesPage__ComingSoon {
  background: $theme;
  box-shadow: 0 0 0 rgba(#000, 0.1);

  @include isDesktop {
    transition: box-shadow 0.2s ease;

    .Button {
      transition: color 0.2s ease, border-color 0.2s ease;
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: $radius;
    box-shadow: 0 0 0 1px $buttonBorderColor inset;
    pointer-events: none;
  }
}
