.--noBreak {
  white-space: nowrap;
}

.Flex--fit {
  min-height: 0;
}

.--textBold {
  font-weight: bold;
}

.--textTruncate,
.--textTruncated {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.--textAlignLeft {
  text-align: left !important;
}

.--textAlignCenter {
  text-align: center !important;
}

.--textAlignRight {
  text-align: right !important;
}

.--noMargin {
  margin: 0 !important;
}

.--alignSelfFlexStart {
  align-self: flex-start !important;
}

.--alignSelfFlexEnd {
  align-self: flex-end !important;
}

.--alignSelfStretch {
  align-self: stretch !important;
}

.--isFontWeightBold {
  font-weight: bold !important;
}

.--isFontWeightLight {
  font-weight: light !important;
}

.--isFontWeightLighter {
  font-weight: lighter !important;
}

.--isUnderlined {
  text-decoration: underline !important;
}

.--isUppercase {
  text-transform: uppercase !important;
}

.--isCapitalized {
  text-transform: capitalize !important;
}

.--isSize1 {
  font-size: $size1 !important;
}

.--isSize2 {
  font-size: $size2 !important;
}

.--isSize3 {
  font-size: $size3 !important;
}

.--isSize4 {
  font-size: $size4 !important;
}

.--isSize5 {
  font-size: $size5 !important;
}

.--isSize6 {
  font-size: $size6 !important;
}

.--isSize7 {
  font-size: $size7 !important;
}

.--isLargeText {
  font-size: 22px !important;
}

.--isMediumText {
  font-size: 18px !important;
}

.--isSmallText {
  font-size: 12px !important;
}

.--isWide {
  width: 100% !important;
}

.--isRelative {
  position: relative !important;
}

.--isInteractive {
  cursor: pointer !important;
}
