body[data-theme=contrast] {
  @import '@/node_modules/react-commons/src/lib/styles/util/themePalette';
  @import '@/node_modules/react-commons/src/lib/styles/util/themeDefaults';

  // Default theme palette
  $swWhite: #FFFFFF;
  $swGrayLight: #DDD;
  $swGrayLighter: #EDEDED;
  $swBlue: #195AAB;
  $swBlueDark: #1a3e6e;
  $swBlueLight: #195AAB;
  $swBlueLightest: #74ADF3;
  $swBlueAlt: #1a3e6e;
  $swGold: #EF8000;

  // Theme variables 
  $theme: $swWhite;
  $themeInverted: $swBlue;
  $bgColor: $swGrayLight;
  $bgColorLight: $swGrayLighter;
  $bgColorSecondary: $swBlueLightest;
  $shadow: 0 0.5em 1em -0.125em rgba(#000, 0.2), 0 0px 0 1px rgba(#000, 0.04) !default;
  $appHeaderBg: $swBlueAlt;
  $appHeaderActiveLinkShadowColor: $swBlueLightest;
  $hrColor: $borderColor;

  $fontColor: $swBlue;
  $lightColor: $swBlueLight;
  $strongColor: $fontColor;
  $titleColor: $fontColor;
  $subtitleColor: $swBlueLight;

  $primaryColor: $swBlue;
  $primaryColorInverted: $swWhite;
  $secondaryColor: $swGold;
  $secondaryColorInverted: $swWhite;
  $tertiaryColor: $swBlueDark;
  $tertiaryColorInverted: $swWhite;

  $boxLinkHoverShadow: $shadow;
  $boxLinkActiveShadow: $shadow;
  
  $errorColor: #DC3535;
  $warningColor: #F49D1A;
  $successColor: #03C988;

  $inputBgColor: $bgColor;
  $inputBorderColor: $bgColor;
  $inputHoverBorderColor: $lightColor;
  $inputIconColor: $lightColor;
  $inputIconActiveColor: $primaryColor;

  $buttonBorderColor: $swBlue;
  $lightBorderColor: #A4A4A4;

  $modalCardHeadBgColor: $theme;
  $modalBgColor: rgba($bgColor, 0.9);
  $modalBgFilter: blur(1px);

  $skeletonTextBg: rgba(#DFE6EC, 0.65); 
  $routerLinkColor: $secondaryColor;

  @keyframes pageLoaderGlowDefault {
    0% {
      background-color: $swBlueLightest;
    }
    45% {
      background-color: lighten($swBlueLightest, 4);
    }
    55% {
      background-color: $swBlueLightest;
    }
    100% {
      background-color: darken($swBlueLightest, 4);
    }
  }
  .PageLoader {
    animation-name: pageLoaderGlowDefault;
  }

  color: $fontColor;
  background-color: $swWhite;
  font-weight: 600;
  font-size: 18px;

  // @include isDesktopDevice {
  //   background-color: $bgColor;
  //   background-image: url('../../public/images/background.jpg');
  //   background-repeat: no-repeat;
  //   background-attachment: fixed;
  //   background-size: cover;
  //   background-position: 50% 0;
  // }

  @import '@/node_modules/react-commons/src/lib/styles/util/themeDerived';
  @import '@/styles/util/theme';
}
